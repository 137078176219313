import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { DATE_TIME_URL_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FormDateTimeField from "../form/FormDateTimeField";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "OrderCustomsFilterForm",
    enableReinitialize: true,
  }),
);

OrderCustomsFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
};

function OrderCustomsFilterForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <FlexBox
      align="center"
      container={16}
      style={{ gap: 14 }}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true}>
        <FormDateTimeField
          fullWidth={true}
          ampmInClock={true}
          formatValue={DATE_TIME_URL_FORMAT}
          placeholder={getLocalisationMessage("from_date")}
          name="fromDateTime"
        />
      </FlexBox>
      <FlexBox flex={true}>
        <FormDateTimeField
          fullWidth={true}
          formatValue={DATE_TIME_URL_FORMAT}
          placeholder={getLocalisationMessage("to_date")}
          name="toDateTime"
        />
      </FlexBox>
      <FlexBox flex={true}>
        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          style={{ backgroundColor: "#3F51B5" }}
          type="submit"
          size="large"
          fullWidth={true}
        >
          {getLocalisationMessage("apply", "Apply")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderCustomsFilterForm);
